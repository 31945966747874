import React from 'react';
import { motion } from 'framer-motion';

const Banner = () => {
  return (
    <motion.section
      className="section banner relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container">
        <div className="row items-center justify-between">
          <motion.div
            className="lg:col-6"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <h1 className="banner-title">
              Elevate Your Interior Design & Operations with Urban Haus
            </h1>
            <motion.p
              className="mt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              A fully integrated suite of Designing and innovating the convenient and eliminates the hassle.
            </motion.p>
            <motion.a
              className="btn btn-white ml-0 m-10"
              href="contact.html"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
            >
              Click Here to Contact
            </motion.a>
          </motion.div>
          <motion.div
            className="lg:col-6"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <img
              className="w-full object-contain"
              src="images/banner-img.png"
              alt="Banner"
            />
          </motion.div>
        </div>
      </div>
      <motion.img
        className="banner-shape absolute -top-28 right-0 -z-[1] w-full max-w-[30%]"
        src="images/banner-shape.svg"
        alt="Banner Shape"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
      />
    </motion.section>
  );
};

export default Banner;