import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { Helmet } from 'react-helmet';

const ServiceSection = ({ titleId, title, imageSrc, imageAlt, items, description }) => (
  <div className="row gx-5 mt-16 items-center">
    <div className="lg:col-7 lg:order-2">
      <div className="relative">
        <img className="w-full object-contain" src={imageSrc} alt={imageAlt} />
      </div>
    </div>
    <div className="mt-6 lg:col-5 lg:order-1 lg:mt-0">
      <div className="text-container">
        <h2 id={titleId} className="lg:text-4xl">{title}</h2>
        <ul className="mt-6 text-dark lg:-ml-4" aria-labelledby={titleId}>
          {items.map((item, index) => (
            <li key={index} className="mb-2 flex items-center rounded px-4">
              <img className="mr-3" src="images/icons/checkmark-circle.svg" alt="Checkmark icon" />
              {item}
            </li>
          ))}
        </ul>
        <p className="mt-6 font-medium text-dark">{description}</p>
      </div>
    </div>
  </div>
);

const Process = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: false, // Animation only occurs once
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Process - Interior and Furniture Design Services</title>
        <meta name="description" content="Discover our process for interior design, furniture design, and consultation services to transform and elevate your space." />
        <meta name="keywords" content="Interior Design, Furniture Design, Consultation Services, Custom Furniture, Space Planning, Color Consultation" />
      </Helmet>

      <section aria-labelledby="process-title">
        <div className="container">
          <ServiceSection
            titleId="interior-design-title"
            title="Interior Design Services"
            imageSrc="images/3.gif"
            imageAlt="Interior Design Services"
            items={[
              "Residential Interior Design",
              "Commercial Interior Design",
              "Customized Interior Solutions"
            ]}
            description="Transform your space with personalized interior design solutions."
          />
          
          <ServiceSection
            titleId="furniture-design-title"
            title="Furniture Design Services"
            imageSrc="images/2.gif"
            imageAlt="Furniture Design Services"
            items={[
              "Custom Furniture Design",
              "Furniture Refinishing and Restoration",
              "Space Optimization Solutions",
              "Tailored Furniture Layouts"
            ]}
            description="Elevate your space with custom-designed furniture tailored to your needs."
          />
          
          <ServiceSection
            titleId="consultation-title"
            title="Consultation Services"
            imageSrc="images/3.gif"
            imageAlt="Consultation Services"
            items={[
              "Space Planning and Layout",
              "Color Consultation",
              "Material and Finish Selection"
            ]}
            description="Receive expert advice and guidance to bring your vision to life."
          />
        </div>
      </section>
    </>
  );
};

export default Process;
