import React from 'react'
import Banner from '../components/home/Banner'
import KeyFeatures from '../components/home/Keyfeatures'
import Services from '../components/home/Services'
import Reviews from '../components/home/Reviews'
import CallToContact from '../components/comman/CallToContact'

function home() {
  return (
    <div>
        <Banner />
        <KeyFeatures />
        <Services />
        <Reviews />
        <CallToContact />

    </div>
  )
}

export default home