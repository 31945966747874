import React from 'react';
import './hero.css'; // Import the external CSS file if you're using it

const HeroSection = () => {
  return (
    <>
    <section className="relative h-screen w-full flex items-center justify-center text-center bg-cover bg-center hero-background">
       
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

      <main className="px-4 sm:px-6 lg:px-8 z-10">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight leading-10 font-medium sm:text-5xl text-white sm:leading-none md:text-6xl">
            <span className="text-primary font-bold">Welcome to Urban Haus</span> - Redefining Spaces with Elegance
          </h1>
          <p className="mt-3 text-white sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5">
            Discover the art of bespoke interiors and furniture design. At Urban Haus, we blend modern aesthetics with timeless elegance to create spaces that inspire.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex justify-center">
            <div className="rounded-md shadow">
              <a href="#serviecs" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-regular rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:border-primary focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:px-10">
                Get Started
              </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <a href="/contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-regular rounded-md text-primary bg-indigo-100 hover:text-primary-dark hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:px-10">
                Live Demo
              </a>
            </div>
          </div>
        </div>
      </main>

      
    </section>
    <div className="container">
          <div className="page-hero-content mx-auto max-w-[883px] text-center">
            <h1 className="mb-5 mt-8">
              Our Structured Approach Helps You Navigate Uncertainties
            </h1>
            <p>
              Urban Haus is dedicated to creating bespoke interiors and furniture that redefine living spaces. With a passion for craftsmanship and attention to detail, we blend modern aesthetics with timeless elegance. Our mission is to inspire and elevate every environment we touch, from residential homes to commercial spaces. Explore our curated collections and experience the artistry of Urban Haus today.
            </p>
          </div>
        </div>
    </>
  );
};

export default HeroSection;
