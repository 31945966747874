import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const KeyFeatures = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: false, // Animation only occurs once
    });
  }, []);

  return (
    <section className="section key-feature relative py-20">
      <img
        className="absolute left-0 top-0 -z-[1] -translate-y-1/2"
        src="images/icons/feature-shape.svg"
        alt="Decorative Feature Shape"
      />
      <div className="container">
        <div className="row justify-between text-center lg:text-start">
          <div className="lg:col-5">
            <h2 className="text-3xl font-bold" data-aos="fade-up">
              Key Features of Our Interior Design Solutions
            </h2>
          </div>
          <div className="mt-6 lg:col-5 lg:mt-0" data-aos="fade-up" data-aos-delay="200">
            <p className="text-lg leading-relaxed">
              At Urban Haus, we specialize in crafting distinctive interiors that not only reflect your style but also enhance functionality. Our all-encompassing design solutions offer a blend of creativity and practicality, tailored to elevate every aspect of your living and working spaces.
            </p>
          </div>
        </div>
        <div className="key-feature-grid mt-10 grid grid-cols-2 gap-7 md:grid-cols-3 xl:grid-cols-4">
          {/* Key Feature 1 */}
          <div className="feature-item p-6 bg-white shadow-lg rounded-lg" data-aos="fade-up" data-aos-delay="300">
            <h3 className="text-xl font-semibold">Innovative Design Solutions</h3>
            <p className="text-base">
              Our innovative approach combines modern aesthetics with practical design to create unique spaces that are both beautiful and functional.
            </p>
          </div>
          {/* Key Feature 2 */}
          <div className="feature-item p-6 bg-white shadow-lg rounded-lg" data-aos="fade-up" data-aos-delay="400">
            <h3 className="text-xl font-semibold">Premium Materials</h3>
            <p className="text-base">
              We use only the highest quality materials to ensure that every detail of your space is crafted to perfection and built to last.
            </p>
          </div>
          {/* Key Feature 3 */}
          <div className="feature-item p-6 bg-white shadow-lg rounded-lg" data-aos="fade-up" data-aos-delay="500">
            <h3 className="text-xl font-semibold">Customized Solutions</h3>
            <p className="text-base">
              Our team provides personalized design solutions that cater specifically to your needs, ensuring that your space is a true reflection of your vision.
            </p>
          </div>
          {/* Key Feature 4 */}
          <div className="feature-item p-6 bg-white shadow-lg rounded-lg" data-aos="fade-up" data-aos-delay="600">
            <h3 className="text-xl font-semibold">End-to-End Service</h3>
            <p className="text-base">
              From initial consultation to project completion, we offer a seamless experience, managing every aspect of your interior design project with expertise and care.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;
